[class^='iap'] {
  --global-font-body: Inter, Roboto, Helvetica Neue, Arial Nova, Nimbus Sans,
    Arial, sans-serif;
  --global-font-mono: 'Space Mono', monospace;
  --fonts-code: var(--global-font-mono);
}

/* Body should be transparent because we show content in iframes */
body {
  background: transparent;
}
